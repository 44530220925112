import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WirUeberUnsSubmenu from "../../components/wirueberuns/wirueberuns_submenu"
import WirUeberUnsContentHeader from "../../components/wirueberuns/wirueberuns_contentHeader"
import TermineMargin from "../../components/termine-margin"
import { graphql } from "gatsby"
import asset1 from "../../assets/2024-06-04_Angebote für unsere Projektwoche vom 25.pdf"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const projekte = ({data}) => (
  <Layout>
    <SEO title="Projekte"/>

    <WirUeberUnsContentHeader headline="UNSERE PROJEKTE" motto={data.allSanitySiteSettings.nodes[0].motto}/>

    <section id="content_2_schule">
      <WirUeberUnsSubmenu/>

      <article id="con_2_rechts">
        <h3 className="small_sub"><span style={{color:"#007c34"}}>PROJEKTE</span></h3>

        <p className="text"><span style={{fontWeight: "bold"}}></span></p><br />

        <ul>
          <li className="aufzaehlung_gruener_kreis">Musikunterricht in der Klasse 1 durch die Musikschule</li>
          <li className="aufzaehlung_gruener_kreis">Klassengemeinschaftstage RIFF</li>
          <li className="aufzaehlung_gruener_kreis">Jugendkunstschule</li>
          <li className="aufzaehlung_gruener_kreis">&quot;Mein K&ouml;rper geh&ouml;rt mir&quot; &#45; Projekt mit der
            Theaterwerkstatt Osnabr&uuml;ck
          </li>
          <li className="aufzaehlung_gruener_kreis">ADAC-Fahrradturnier</li>
          <li className="aufzaehlung_gruener_kreis">Projekttage mit Schulfest</li>
          <li className="aufzaehlung_gruener_kreis">Tag der Bewegung und Ern&auml;hrung</li>
          <li className="aufzaehlung_gruener_kreis">&quot;Klasse 2000&quot; &#45; Gesundheitsf&ouml;rderung</li>
          <li className="aufzaehlung_gruener_kreis">Musikprojekt mit der Nordwestdeutschen Philharmonie</li>
          <li className="aufzaehlung_gruener_kreis">B&uuml;chereitage</li>
          <li className="aufzaehlung_gruener_kreis">Besuch des Heimatmuseums</li>
          <li className="aufzaehlung_gruener_kreis">Informationsabend Leistungsbewertung</li>
          <li className="aufzaehlung_gruener_kreis">Lesepatenschaft mit Sch&uuml;lern der 8. Klassen des St&auml;dt.
            Gymnasiums
            L&ouml;hne (f&uuml;r Kinder der 2. Klassen)
          </li>
          <li className="aufzaehlung_gruener_kreis">Projektwoche - Europa zu Gast in Deutschland (<a href={asset1}>PDF</a>)</li>
        </ul>
      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default projekte
